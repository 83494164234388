export const darkModeKey = "darkMode";

export const styleKey = "style";

function getEnvVar(name) {
  return import.meta.env
    ? import.meta.env["VITE_" + name]
    : process.env["VUE_APP_" + name];
}

export const apiBasePath = getEnvVar("API_BASE_PATH");
export const wsBasePath = getEnvVar("WS_BASE_PATH");
export const singleWebsocket = getEnvVar("SINGLE_WEBSOCKET") === "true";
