import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";

export enum NotificationType {
  ALERT,
}

export enum NotificationSeverity {
  INFO,
  WARNING,
  ERROR,
}

export enum NotificationEntityType {
  MAINTENANCE,
  SNIPE,
  ACCOUNT,
  COPY_CONFIGURATION,
  FEE,
}

export enum NotificationMessageKey {
  MAINTENANCE_BETWEEN,
}

export type NotificationMessage = {
  translationKey: string;
  numberOfParameters: number;
  type: NotificationType;
  severity: NotificationSeverity;
  entityType: NotificationEntityType;
};

export const notificationMessages: Record<
  NotificationMessageKey,
  NotificationMessage
> = {
  [NotificationMessageKey.MAINTENANCE_BETWEEN]: {
    translationKey: "notifications.maintenance-between",
    numberOfParameters: 2,
    type: NotificationType.ALERT,
    severity: NotificationSeverity.INFO,
    entityType: NotificationEntityType.MAINTENANCE,
  },
};

export type NotificationEntityId = number | string;

export type NotificationId = string;

export type RestNotificationCreateRequest = {
  userId?: string;

  active: boolean;
  dismissed: boolean;

  key: NotificationMessageKey;
  parameters: string[];

  entityId?: NotificationEntityId;
};

export type NotificationCreateRequest = RestNotificationCreateRequest & {
  createdAt: Date;
};

export type RestNotificationUpdateRequest = {
  id: NotificationId;
  userId: string;

  active: boolean;
  dismissed: boolean;

  key: NotificationMessageKey;
  parameters: string[];

  entityId?: NotificationEntityId;
};

export type NotificationUpdateRequest = RestNotificationUpdateRequest & {
  updatedAt: Date;
};

export type Notification = NotificationUpdateRequest & {
  createdAt: Date;
  updatedAt: Date;
};

export type RestNotification = Notification & NotificationMessage;

export type NotificationsState = {
  items: RestNotification[];
};

const notificationsState: NotificationsState = {
  items: [],
};

const notificationsGetters: GetterTree<NotificationsState, RootState> = {
  maximumSeverity(state): NotificationSeverity {
    let severity = NotificationSeverity.INFO;

    state.items.forEach((i) => {
      if (i.severity > severity) {
        severity = i.severity;
      }
    });

    return severity;
  },
};

const notificationsMutations: MutationTree<NotificationsState> = {
  set(state, payload: RestNotification) {
    const index = state.items.findIndex(
      (notification: any) => payload.id === notification.id
    );

    if (index < 0) {
      state.items.push(payload);
    } else {
      state.items[index] = payload;
    }
  },
};

const notificationsActions: ActionTree<NotificationsState, RootState> = {
  async read({ state }) {
    try {
      const response = await axios.get(apiBasePath + "/notifications");
      state.items = response.data.map((a: any) => ({
        ...a,
        createdAt: new Date(a.createdAt),
        updatedAt: new Date(a.updatedAt),
      }));
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async dismiss({ state }, payload: NotificationId) {
    try {
      await axios.post(`${apiBasePath}/notifications/${payload}/dismiss`);
      showSuccess("notifications.successfully-dismissed");
      state.items = state.items.filter((i) => i.id !== payload);
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const notificationsModule: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: notificationsState,
  getters: notificationsGetters,
  mutations: notificationsMutations,
  actions: notificationsActions,
};

export default notificationsModule;
