import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export type GiftCode = {
  code: string;
  amount: number;
  email: string;
  userId?: string;

  createdAt: Date;
  activatedAt?: Date;
  expiresAt?: Date;
};

export interface GiftCodeState {
  items: GiftCode[];
}

const giftCodeState: GiftCodeState = {
  items: [],
};

const giftCodeGetters: GetterTree<GiftCodeState, RootState> = {
  items(state) {
    return state.items;
  },
};

const giftCodeMutations: MutationTree<GiftCodeState> = {
  set(state, payload: any[]) {
    state.items = payload.map((giftCode) => ({
      ...giftCode,
      activatedAt: giftCode.activatedAt
        ? new Date(giftCode.activatedAt)
        : undefined,
      expiresAt: giftCode.expiresAt ? new Date(giftCode.expiresAt) : undefined,
    }));
  },
};

const giftCodeActions: ActionTree<GiftCodeState, RootState> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/giftCode")
      .then((response) => {
        commit("set", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  activate(_, payload) {
    axios
      .post(`${apiBasePath}/giftCode/activate`, payload)
      .then(() => {})
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const giftCodeModule: Module<GiftCodeState, RootState> = {
  namespaced: true,
  state: giftCodeState,
  getters: giftCodeGetters,
  mutations: giftCodeMutations,
  actions: giftCodeActions,
};

export default giftCodeModule;
