import { apiBasePath } from "@/config";
import { showSuccess, showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { SnipeSignalSourceId } from "../signals/store";

export type SnipeAutomationConfigurationId = string;

export type RestSnipeAutomationConfigurationCreateRequest = {
  name: string;
  configuration: any;
  minimumLiquidity?: number;
  maximumLiquidity?: number;
  enabledSources?: SnipeSignalSourceId[];
};

export type RestSnipeAutomationConfigurationUpdateRequest =
  RestSnipeAutomationConfigurationCreateRequest & {
    id: SnipeAutomationConfigurationId;
    createdAt: Date;
  };

export type SnipeAutomationConfiguration =
  RestSnipeAutomationConfigurationUpdateRequest & {
    userId: string;
    enabledSources: SnipeSignalSourceId[];
    updatedAt: Date;
  };

export type SnipeSourceModificationRequest = {
  configurationId: SnipeAutomationConfigurationId;
  sourceId: SnipeSignalSourceId;
  userId: string;
};

export type SnipeAutomationConfigurationState = {
  items: SnipeAutomationConfiguration[];
};

const snipeAutomationConfigurationState: SnipeAutomationConfigurationState = {
  items: [],
};

const snipeAutomationConfigurationGetters: GetterTree<
  SnipeAutomationConfigurationState,
  RootState
> = {
  items(state) {
    return state.items;
  },
  itemsOnChain(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    return state.items.filter((s) => s.configuration.chain === rootState.chain);
  },
};

const snipeAutomationConfigurationMutations: MutationTree<SnipeAutomationConfigurationState> =
  {
    set(state, payload: any[]) {
      state.items = payload;
    },
    delete(state, payload: SnipeAutomationConfigurationId) {
      state.items = state.items.filter((s) => s.id !== payload);
    },
  };

const snipeAutomationConfigurationActions: ActionTree<
  SnipeAutomationConfigurationState,
  RootState
> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/snipeAutomation/configuration")
      .then((response) => {
        commit("set", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  async create({ dispatch }, payload) {
    try {
      const response = await axios.post(
        apiBasePath + "/snipeAutomation/configuration",
        payload
      );
      if (response.status === 200) {
        showSuccess("snipe-automation-config-created");

        dispatch("snipeAutomationConfiguration/read", null, { root: true });

        return true;
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async update({ dispatch }, payload) {
    try {
      const response = await axios.put(
        apiBasePath + "/snipeAutomation/configuration",
        payload
      );
      if (response.status === 200) {
        showSuccess("snipe-automation-config-updated");

        dispatch("snipeAutomationConfiguration/read", null, { root: true });

        return true;
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  delete({ commit, dispatch }, payload) {
    axios
      .delete(apiBasePath + `/snipeAutomation/configuration/${payload}`)
      .then((response) => {
        showSuccess("snipe-automation-config-deleted");

        if (response.status === 200) {
          commit("delete", payload);
        }

        dispatch("snipeAutomationConfiguration/read", null, { root: true });
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const snipeAutomationConfigurationsModule: Module<
  SnipeAutomationConfigurationState,
  RootState
> = {
  namespaced: true,
  state: snipeAutomationConfigurationState,
  getters: snipeAutomationConfigurationGetters,
  mutations: snipeAutomationConfigurationMutations,
  actions: snipeAutomationConfigurationActions,
};

export default snipeAutomationConfigurationsModule;
