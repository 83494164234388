<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useScroll } from "@vueuse/core";
import getMenuItems from "@/menu";
import NavBar from "@/components/NavBar.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import Overlay from "@/components/Overlay.vue";
import Spinner from "@/components/Spinner.vue";

const store = useStore();

const isAsideLgActive = computed(() => store.state.isAsideLgActive);
const isLoading = computed(
  () =>
    store.state.auth.loggedIn &&
    !store.state.auth.initialValidationOngoing &&
    store.state.initialLoadRunning
);

const overlayClick = () => {
  store.dispatch("asideLgToggle", false);
};

const showNonAvailablePackageWarning = computed(() => {
  const activePackage = store.getters["packages/active"];
  if (!activePackage) {
    return false;
  }

  return !activePackage.isAvailable;
});

const content = ref(null);
const scroll = useScroll(window, {
  onScroll: () => {
    if (store.state.showFloatingButtons !== !scroll.arrivedState.bottom) {
      store.commit("basic", {
        key: "showFloatingButtons",
        value: !scroll.arrivedState.bottom,
      });
    }
  },
});
</script>

<template>
  <notifications position="top right" class="mt-3 mr-3" />
  <div
    v-if="isLoading"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <spinner />
  </div>
  <div v-else>
    <nav-bar />

    <div
      v-if="showNonAvailablePackageWarning"
      class="bg-orange-500 px-4 py-3 text-center"
      role="alert"
    >
      <span class="block sm:inline">
        {{
          $t(
            "your-package-is-not-available-anymore-it-will-not-be-renewed-automatically-please-activate-another-one-when-the-previous-has-expired"
          )
        }}
      </span>
    </div>

    <aside-menu :menu="getMenuItems()" />
    <router-view ref="content" />
    <overlay
      v-show="isAsideLgActive"
      z-index="z-30"
      @overlay-click="overlayClick"
    />
  </div>
</template>
