import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";

export type UserId = string;
export enum UserRoles {
  USER = "user",
  ADMIN = "admin",
  SNIPE_TESTER = "snipeTester",
  COPY_TRADER = "copyTrader",
}

export interface User {
  id: UserId;

  email: string;
  password: string;
  name: string;
  roles: UserRoles[];

  invitedBy?: UserId;
  inviteCode: string;

  validated: boolean;
  trained: boolean;
  twoFactorAuthenticationEnabled: boolean;
  showHiddenBalances: boolean;
  showZeroBalances: boolean;

  createdAt: Date;
  updatedAt: Date;
}

export type UserAdminitratorUpdateRequest = {
  userId: UserId;
  disable2FA?: boolean;
  trained?: boolean;
  email?: string;
  name?: string;
  roles?: UserRoles[];
};

export type UserAdministrationState = {
  users: User[];
};

const userAdministrationState: UserAdministrationState = {
  users: [],
};

const userAdministrationGetters: GetterTree<
  UserAdministrationState,
  RootState
> = {};

const userAdministrationMutations: MutationTree<UserAdministrationState> = {};

const userAdministrationActions: ActionTree<
  UserAdministrationState,
  RootState
> = {
  async read({ state }) {
    try {
      const response = await axios.get(apiBasePath + "/administration/user");

      state.users = response.data.map((u: any) => ({
        ...u,
        createdAt: new Date(u.createdAt),
      }));
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const userAdministrationModule: Module<UserAdministrationState, RootState> = {
  namespaced: true,
  state: userAdministrationState,
  getters: userAdministrationGetters,
  mutations: userAdministrationMutations,
  actions: userAdministrationActions,
};

export default userAdministrationModule;
