import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import {
  Notification,
  NotificationId,
  RestNotificationCreateRequest,
} from "@/notifications/store";

export type NotificationAdministrationState = {
  notifications: Notification[];
};

const notificationAdministrationState: NotificationAdministrationState = {
  notifications: [],
};

const notificationAdministrationGetters: GetterTree<
  NotificationAdministrationState,
  RootState
> = {};

const notificationAdministrationMutations: MutationTree<NotificationAdministrationState> =
  {};

const notificationAdministrationActions: ActionTree<
  NotificationAdministrationState,
  RootState
> = {
  async read({ state }) {
    try {
      const response = await axios.get(
        apiBasePath + "/administration/notifications"
      );
      state.notifications = response.data.map((a: any) => ({
        ...a,
        createdAt: new Date(a.createdAt),
        updatedAt: new Date(a.updatedAt),
      }));
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async create({ dispatch }, payload: RestNotificationCreateRequest) {
    try {
      await axios.post(`${apiBasePath}/administration/notifications`, payload);
      showSuccess("administration.notification.successfully-created");
      dispatch("read");
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const notificationAdministrationModule: Module<
  NotificationAdministrationState,
  RootState
> = {
  namespaced: true,
  state: notificationAdministrationState,
  getters: notificationAdministrationGetters,
  mutations: notificationAdministrationMutations,
  actions: notificationAdministrationActions,
};

export default notificationAdministrationModule;
