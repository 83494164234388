import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";

export type CopyTraderAccountStatistics = {
  profit: {
    day: number;
    month: number;
    year: number;
  };
};

export type CopyTraderAccount = {
  userId: string;
  chain: string;
  publicKey: string;
  name?: string;
  statistics: CopyTraderAccountStatistics;
  createdAt: Date;
};

export type CopyTradeAccountState = {
  items: CopyTraderAccount[];
};

const copyTradeAccountState: CopyTradeAccountState = {
  items: [],
};

const copyTradeAccountGetters: GetterTree<CopyTradeAccountState, RootState> = {
  itemsOnChain(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    const items = state.items.filter((s) => s.chain === rootState.chain);

    items.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    return items;
  },
};

const copyTradeAccountMutations: MutationTree<CopyTradeAccountState> = {
  set(state, payload: any[]) {
    state.items = payload;
  },
};

const copyTradeAccountActions: ActionTree<CopyTradeAccountState, RootState> = {
  async read({ state }) {
    try {
      const response = await axios.get(apiBasePath + "/copyTrade/account");
      state.items = response.data.map((a: any) => ({
        ...a,
        createdAt: new Date(a.createdAt),
      }));
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async makeCopyable({ dispatch, rootState }, payload: string) {
    try {
      await axios.post(apiBasePath + "/copyTrade/account", {
        chain: rootState.chain,
        publicKey: payload,
      });

      dispatch("read");

      showSuccess("copy-trade.create-copyable-account-success");
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const copyTradeAccountModule: Module<CopyTradeAccountState, RootState> = {
  namespaced: true,
  state: copyTradeAccountState,
  getters: copyTradeAccountGetters,
  mutations: copyTradeAccountMutations,
  actions: copyTradeAccountActions,
};

export default copyTradeAccountModule;
