import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export type Voucher = {
  code: string;
  amount: number;
  userId: string;
  createdAt: Date;
  activatedAt: Date;
};

export interface VoucherState {
  items: Voucher[];
}

const voucherState: VoucherState = {
  items: [],
};

const voucherGetters: GetterTree<VoucherState, RootState> = {
  items(state) {
    return state.items;
  },
};

const voucherMutations: MutationTree<VoucherState> = {
  set(state, payload: any[]) {
    state.items = payload.map((voucher) => ({
      ...voucher,
      activatedAt: new Date(voucher.activatedAt),
    }));
  },
};

const voucherActions: ActionTree<VoucherState, RootState> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/voucher")
      .then((response) => {
        commit("set", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  async activate(_, payload) {
    try {
      await axios.post(`${apiBasePath}/voucher/activate`, payload);
    } catch (error) {
      showApiErrorResponse(error);
      throw error;
    }
  },
};

const voucherModule: Module<VoucherState, RootState> = {
  namespaced: true,
  state: voucherState,
  getters: voucherGetters,
  mutations: voucherMutations,
  actions: voucherActions,
};

export default voucherModule;
