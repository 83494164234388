import axios from "axios";
import router from "@/router";
import { apiBasePath } from "@/config";
import { showSuccess, showApiErrorResponse } from "@/notify";
import { createWebSockets, closeAllWebsockets } from "@/websocket";
import jwtDecode from "jwt-decode";
import { ActionTree } from "vuex";
import { RootState } from ".";

export interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  userId?: string;
  twoFactorAuthenticationEnabled: boolean;
  loggingIn: boolean;
  loggedIn: boolean;
  registrationOngoing: boolean;
  initialValidationOngoing: boolean;
  requestOneTimePassword: boolean;
  accessTokenExpiresAt?: Date;
  refreshTokenExpiresAt?: Date;
  remember: boolean;
  impersonatedUser?: string;
}

const state: AuthState = {
  remember: false,
  twoFactorAuthenticationEnabled: false,
  loggingIn: false,
  loggedIn: false,
  registrationOngoing: false,
  initialValidationOngoing: true,
  requestOneTimePassword: false,
};

const getStorage = () => {
  return state.remember ? localStorage : sessionStorage;
};

const getters = {};

const mutations = {
  loggedIn(
    // @ts-ignore
    state,
    {
      accessToken,
      refreshToken,
      userId,
      twoFactorAuthenticationEnabled,
      impersonatedUser,
    }: any
  ) {
    axios.defaults.headers.common.Authorization = accessToken;

    state.loggedIn = true;
    state.loggingIn = false;
    state.initialValidationOngoing = false;
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
    state.userId = userId;
    state.twoFactorAuthenticationEnabled = twoFactorAuthenticationEnabled;
    state.requestOneTimePassword = false;
    state.impersonatedUser = impersonatedUser;

    let tokenData = jwtDecode(accessToken);
    // @ts-ignore
    state.accessTokenExpiresAt = tokenData.exp;

    if (refreshToken) {
      tokenData = jwtDecode(refreshToken);
      // @ts-ignore
      state.refreshTokenExpiresAt = tokenData.exp;
    }

    createWebSockets();
  },
  // @ts-ignore
  refreshAccessToken(state, { accessToken }) {
    axios.defaults.headers.common.Authorization = accessToken;
    state.accessToken = accessToken;
    const tokenData = jwtDecode(accessToken);
    // @ts-ignore
    state.accessTokenExpiresAt = tokenData.exp;
    getStorage().setItem("auth", JSON.stringify(state));
  },
  // @ts-ignore
  loggingIn(state) {
    state.loggedIn = false;
    state.loggingIn = true;
    state.accessToken = null;
    state.tokenExpiresAt = null;
    state.userId = null;
    state.accessTokenExpiresAt = null;
    state.refreshTokenExpiresAt = null;
  },
  // @ts-ignore
  loggingInFailure(state) {
    state.loggedIn = false;
    state.loggingIn = false;
    state.accessToken = null;
    state.tokenExpiresAt = null;
    state.userId = null;
    state.accessTokenExpiresAt = null;
    state.refreshTokenExpiresAt = null;
  },
  // @ts-ignore
  requestOneTimePassword(state, { accessToken, userId }) {
    axios.defaults.headers.common.Authorization = accessToken;

    state.requestOneTimePassword = true;
    state.loggingIn = false;
    state.userId = userId;
    state.accessToken = accessToken;
    state.accessTokenExpiresAt = null;
    state.refreshTokenExpiresAt = null;
  },
  // @ts-ignore
  logOut(state) {
    state.loggedIn = false;
    state.loggingIn = false;
    state.initialValidationOngoing = false;
    state.accessToken = null;
    state.tokenExpiresAt = null;
    state.userId = null;
    state.requestOneTimePassword = false;
    state.accessTokenExpiresAt = null;
    state.refreshTokenExpiresAt = null;

    delete axios.defaults.headers.common.Authorization;
  },
  // @ts-ignore
  registrationStarted(state) {
    state.registrationOngoing = true;
  },
  // @ts-ignore
  registrationFinished(state) {
    state.registrationOngoing = false;
  },
  // @ts-ignore
  setTwoFactorAuthenticationEnabled(state, { value }) {
    state.twoFactorAuthenticationEnabled = value;
  },
  impersonate(state: AuthState, { accessToken }: { accessToken: string }) {
    axios.defaults.headers.common.Authorization = accessToken;
    state.accessToken = accessToken;
    const tokenData: any = jwtDecode(accessToken);
    state.accessTokenExpiresAt = tokenData.exp;
    state.impersonatedUser = tokenData.userId;
    getStorage().setItem("auth", JSON.stringify(state));
  },
  leaveImpersonate(state: AuthState) {
    delete state.impersonatedUser;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async fetchAllState({ dispatch }) {
    try {
      await Promise.all([
        dispatch("readTokenInfo", null, { root: true }),
        dispatch("readChainDetails", null, { root: true }),
        dispatch("readUserDetails", null, { root: true }),
        dispatch("readAccounts", null, { root: true }),
        dispatch("readWithdrawals", null, { root: true }),
        dispatch("readDeposits", null, { root: true }),
        dispatch("readActiveSnipes", null, { root: true }),
        dispatch("readSnipeStatistics", null, { root: true }),
        dispatch("packages/read", null, { root: true }),
        dispatch("presets/read", null, { root: true }),
        dispatch("fees/read", null, { root: true }),
        dispatch("vouchers/read", null, { root: true }),
        // XXX GIFT CODES  dispatch("giftCodes/read", null, { root: true });
        dispatch("snipeSignals/read", null, { root: true }),
        dispatch("snipeAutomationConfiguration/read", null, { root: true }),
        dispatch("copyTradeConfiguration/read", null, { root: true }),
        dispatch("copyTradeAccount/read", null, { root: true }),
        dispatch("notifications/read", null, { root: true }),
      ]);
    } finally {
      dispatch("setInitialLoading", false, { root: true });
    }
  },

  fetchToken({ dispatch, commit, state }, payload) {
    commit("loggingIn");
    localStorage.removeItem("auth");
    sessionStorage.removeItem("auth");
    let request;

    if (payload.oneTimePassword) {
      request = axios.post(apiBasePath + "/auth/login/oneTimePassword", {
        oneTimePassword: payload.oneTimePassword,
      });
    } else {
      request = axios.post(apiBasePath + "/auth/login", {
        email: payload.email,
        password: payload.password,
      });
    }

    request
      .then((response) => {
        if (
          response.data.twoFactorAuthenticationEnabled &&
          !state.requestOneTimePassword
        ) {
          commit("requestOneTimePassword", {
            accessToken: response.data.accessToken,
            userId: response.data.userId,
            twoFactorAuthenticationEnabled:
              response.data.twoFactorAuthenticationEnabled,
          });
        } else {
          commit("loggedIn", {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            userId: response.data.userId,
            twoFactorAuthenticationEnabled:
              response.data.twoFactorAuthenticationEnabled,
          });
          showSuccess("login-success");

          dispatch("fetchAllState");

          if (
            payload.remember &&
            payload.remember.length === 1 &&
            payload.remember[0] === "remember"
          ) {
            state.remember = true;
          } else {
            state.remember = false;
          }

          getStorage().setItem("auth", JSON.stringify(state));

          router.push({ name: "dashboard" });
        }
      })
      .catch((error) => {
        commit("loggingInFailure");
        showApiErrorResponse(error);
      });
  },
  async refreshAccessToken({ commit, state }) {
    if (state.refreshToken) {
      axios.defaults.headers.common.Authorization = state.refreshToken;
      try {
        let response;
        if (!state.impersonatedUser) {
          response = await axios.post(apiBasePath + "/auth/refresh");
        } else {
          response = await axios.post(
            `${apiBasePath}/auth/impersonate/${state.impersonatedUser}`
          );
        }
        commit("refreshAccessToken", {
          accessToken: response.data.accessToken,
        });
      } catch (error) {
        showApiErrorResponse(error);
      }
    }
  },
  register({ commit }, payload) {
    commit("registrationStarted");
    axios
      .post(apiBasePath + "/auth/register", payload)
      .then(() => {
        commit("registrationFinished");
        showSuccess("register-success");
        router.push({ name: "login" });
      })
      .catch((error) => {
        commit("registrationFinished");
        showApiErrorResponse(error);
      });
  },
  async logOut({ commit, state, dispatch }) {
    if (state.impersonatedUser) {
      commit("leaveImpersonate");
      await dispatch("refreshAccessToken");
      await dispatch("fetchAllState");
      showSuccess("leave-impersonate-success");
      router.push({ name: "userAdministration" });
    } else {
      commit("logOut");
      localStorage.clear();
      sessionStorage.clear();
      showSuccess("logout-success");
      // XXX landing router.push({ name: "landing" });
      router.push({ name: "login" });
    }
    closeAllWebsockets();
  },
  async loadStoredCredentials({ commit, dispatch }) {
    const storedAuthDataString =
      sessionStorage.getItem("auth") ?? localStorage.getItem("auth");
    let storedAuthData: AuthState | undefined;
    if (storedAuthDataString) {
      storedAuthData = JSON.parse(storedAuthDataString);
    }

    if (storedAuthData && storedAuthData.refreshToken) {
      axios.defaults.headers.common.Authorization = storedAuthData.refreshToken;
      try {
        let response;
        if (!storedAuthData.impersonatedUser) {
          response = await axios.post(apiBasePath + "/auth/refresh");
        } else {
          response = await axios.post(
            `${apiBasePath}/auth/impersonate/${storedAuthData.impersonatedUser}`
          );
        }
        if (response.status === 200) {
          commit("loggedIn", storedAuthData);
          commit("refreshAccessToken", {
            accessToken: response.data.accessToken,
          });

          dispatch("fetchAllState");
        } else {
          dispatch("logOut");
        }
      } catch (error) {
        dispatch("logOut");
      }
    } else {
      router.push({ name: "login" });
      dispatch("logOut");
    }
  },
  changePassword(_, payload) {
    axios
      .post(apiBasePath + "/auth/changePassword", {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      })
      .then(() => {
        showSuccess("password-changed");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  resetPassword(_, payload) {
    axios
      .post(apiBasePath + "/auth/resetPassword", payload)
      .then(() => {
        showSuccess("password-changed");
        router.push({ name: "login" });
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  resetTwoFactorAuth({ commit }) {
    commit("setTwoFactorAuthenticationEnabled", { value: false });
  },
  setTwoFactorAuth({ commit }) {
    commit("setTwoFactorAuthenticationEnabled", { value: true });
  },
  async impersonate({ commit, state, dispatch }, userId) {
    axios.defaults.headers.common.Authorization = state.refreshToken!;
    try {
      const response = await axios.post(
        `${apiBasePath}/auth/impersonate/${userId}`
      );
      if (response.status === 200) {
        showSuccess("impersonate-success");
        commit("impersonate", { accessToken: response.data.accessToken });
        await dispatch("fetchAllState");
        closeAllWebsockets();
        router.push({ name: "dashboard" });
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
