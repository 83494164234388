import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import BN from "bn.js";

export type PerChainSystemStatistics = {
  managedBalance: BN;
  paidFees: BN;
  deposits: BN;
  withdrawals: BN;
  balanceInActiveSnipes: BN;
  runningSnipes: number;
  topUsers: { name: string; email: string; balance: BN }[];
};

export type SystemStatistics = {
  numberOfUsers: number;
  numberOfCopiers: number;
  numberOfUsersWithActiveSnipes: number;
  perChain: Record<string, PerChainSystemStatistics>;
};

export type StatisticsAdministrationState = {
  statistics?: SystemStatistics;
};

const statisticsAdministrationState: StatisticsAdministrationState = {};

const statisticsAdministrationGetters: GetterTree<
  StatisticsAdministrationState,
  RootState
> = {};

const statisticsAdministrationMutations: MutationTree<StatisticsAdministrationState> =
  {};

const statisticsAdministrationActions: ActionTree<
  StatisticsAdministrationState,
  RootState
> = {
  async read({ state }) {
    try {
      const response = await axios.get(
        apiBasePath + "/administration/statistics"
      );

      const jsonBody = response.data;
      state.statistics = {
        numberOfUsers: jsonBody.numberOfUsers,
        numberOfUsersWithActiveSnipes: jsonBody.numberOfUsersWithActiveSnipes,
        numberOfCopiers: jsonBody.numberOfCopiers,
        perChain: {},
      };

      for (const [chain, perChainStatistic] of Object.entries(
        jsonBody.perChain
      )) {
        state.statistics.perChain[chain] = {
          runningSnipes: (perChainStatistic as any).runningSnipes,
          paidFees: new BN((perChainStatistic as any).paidFees),
          managedBalance: new BN((perChainStatistic as any).managedBalance),
          deposits: new BN((perChainStatistic as any).deposits),
          withdrawals: new BN((perChainStatistic as any).withdrawals),
          balanceInActiveSnipes: new BN(
            (perChainStatistic as any).balanceInActiveSnipes
          ),
          topUsers: (perChainStatistic as any).topUsers.map((u: any) => ({
            ...u,
            balance: new BN(u.balance),
          })),
        };
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const statisticsAdministrationModule: Module<
  StatisticsAdministrationState,
  RootState
> = {
  namespaced: true,
  state: statisticsAdministrationState,
  getters: statisticsAdministrationGetters,
  mutations: statisticsAdministrationMutations,
  actions: statisticsAdministrationActions,
};

export default statisticsAdministrationModule;
