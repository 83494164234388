import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

export function showError(message, args) {
  console.error(message);
  notify({
    text: i18n.global.t(message, args),
    type: "error",
  });
}

export function showWarning(message, args) {
  console.warn(message);
  notify({
    text: i18n.global.t(message, args),
    type: "warn",
  });
}

export function showSuccess(message, args) {
  notify({
    text: i18n.global.t(message, args),
    type: "success",
  });
}

export function showInfo(message, args) {
  notify({
    text: i18n.global.t(message, args),
  });
}

export function showApiErrorResponse(error) {
  try {
    for (const message of error.response.data.errors) {
      showError(message);
    }
  } catch (e) {
    showError(error.message);
  }
}
