import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";

export type SnipeSignalId = number;
export type SnipeSignalSourceId = number;
export type PairEventSource = string;

export type SnipeSignalSource = {
  id: SnipeSignalSourceId;
  name: string;

  sourceType: PairEventSource;
  sourceId?: string;

  createdAt: Date;
  updatedAt: Date;
};

export type SnipeSignal = {
  id: SnipeSignalId;

  sourceId: SnipeSignalSourceId;
  source?: SnipeSignalSource;

  chain: string;
  swap: string;

  // TODO Create a common type definition
  pair: {
    from?: string;
    to?: string;
    contract?: string;
  };

  liquidity?: number;

  createdAt: Date;
};

export type SnipeSignalState = {
  signals: SnipeSignal[];
  sources: Map<number, SnipeSignalSource>;
};

const snipeSignalState: SnipeSignalState = {
  signals: [],
  sources: new Map(),
};

const snipeSignalGetters: GetterTree<SnipeSignalState, RootState> = {
  items(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    return state.signals
      .filter((s) => s.chain === rootState.chain)
      .map((s) => {
        s.source = state.sources.get(s.sourceId);
        return s;
      });
  },

  sources(state) {
    return [...state.sources.values()];
  },

  sourceSet(state) {
    return state.sources;
  },
};

const snipeSignalMutations: MutationTree<SnipeSignalState> = {
  add(state, payload: any) {
    state.signals.unshift({
      ...payload,
      createdAt: new Date(payload.createdAt),
    });
  },
};

const snipeSignalActions: ActionTree<SnipeSignalState, RootState> = {
  read({ state }) {
    axios
      .get(apiBasePath + "/snipeAutomation/signal")
      .then((response) => {
        state.signals = response.data.map((s: any) => ({
          ...s,
          createdAt: new Date(s.createdAt),
        }));
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
    axios
      .get(apiBasePath + "/snipeAutomation/source")
      .then((response) => {
        for (const source of response.data) {
          state.sources.set(source.id, source);
        }
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const snipeSignalsModule: Module<SnipeSignalState, RootState> = {
  namespaced: true,
  state: snipeSignalState,
  getters: snipeSignalGetters,
  mutations: snipeSignalMutations,
  actions: snipeSignalActions,
};

export default snipeSignalsModule;
