import { createRouter, createWebHashHistory } from "vue-router";
import Error from "@/views/Error.vue";
import store from "../store";
import i18n from "@/i18n";
const routes = [
  // XXX landing {
  // XXX landing   meta: {
  // XXX landing     title: "SniperBot.io",
  // XXX landing     fullScreen: true,
  // XXX landing   },
  // XXX landing   path: "/",
  // XXX landing   name: "landing",
  // XXX landing   component: () => import("@/views/Landing.vue"),
  // XXX landing },
  {
    path: "/",
    redirect: () => {
      return { path: "/dashboard" };
    },
  },
  {
    meta: {
      title: i18n.global.t("dashboard"),
    },
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    meta: {
      title: i18n.global.t("login.button"),
      fullScreen: true,
    },
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    meta: {
      title: i18n.global.t("register.button"),
      fullScreen: true,
    },
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
  },
  {
    meta: {
      title: i18n.global.t("password-reset.button"),
      fullScreen: true,
    },
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("@/views/PasswordReset.vue"),
  },
  {
    meta: {
      title: i18n.global.t("password-reset.button"),
      fullScreen: true,
    },
    path: "/requestPasswordReset",
    name: "requestPasswordReset",
    component: () => import("@/views/RequestPasswordReset.vue"),
  },
  {
    meta: {
      title: i18n.global.t("profile"),
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
  },
  {
    meta: {
      title: i18n.global.t("active-snipes"),
    },
    path: "/snipe/active",
    name: "activeSnipeList",
    component: () => import("@/views/ActiveSnipeList.vue"),
  },
  {
    meta: {
      title: i18n.global.t("snipe-history"),
    },
    path: "/snipe/history",
    name: "snipeHistory",
    component: () => import("@/views/SnipeHistory.vue"),
  },
  {
    meta: {
      title: i18n.global.t("create-snipe"),
    },
    path: "/snipe/create",
    name: "createSnipe",
    component: () => import("@/views/CreateSnipe.vue"),
  },
  {
    meta: {
      title: i18n.global.t("snipe-details"),
    },
    path: "/snipe/:id",
    name: "snipeDetails",
    component: () => import("@/views/SnipeDetails.vue"),
  },
  {
    meta: {
      title: i18n.global.t("manual-sell"),
    },
    path: "/snipe/:id/sell",
    name: "manualSnipeSell",
    component: () => import("@/views/ManualSellSnipe.vue"),
  },
  {
    meta: {
      title: i18n.global.t("copy-snipe"),
    },
    path: "/snipe/:id/copy",
    name: "copySnipe",
    component: () => import("@/views/CopySnipe.vue"),
  },
  {
    meta: {
      title: i18n.global.t("accounts"),
    },
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/Accounts.vue"),
  },
  {
    meta: {
      title: i18n.global.t("account-details"),
    },
    path: "/accounts/:publicKey",
    name: "accountDetails",
    component: () => import("@/views/AccountDetails.vue"),
  },
  {
    meta: {
      title: i18n.global.t("packages"),
    },
    path: "/packages",
    name: "packages",
    component: () => import("@/views/Packages.vue"),
  },
  {
    meta: {
      title: i18n.global.t("vouchers"),
    },
    path: "/vouchers",
    name: "vouchers",
    component: () => import("@/views/Vouchers.vue"),
  },
  // XXX GIFT CODES  {
  // XXX GIFT CODES    meta: {
  // XXX GIFT CODES      title: i18n.global.t("gift-codes"),
  // XXX GIFT CODES    },
  // XXX GIFT CODES    path: "/giftCodes",
  // XXX GIFT CODES    name: "giftCodes",
  // XXX GIFT CODES    component: () => import("@/views/GiftCodes.vue"),
  // XXX GIFT CODES  },
  {
    meta: {
      title: i18n.global.t("presets"),
    },
    path: "/presets/list",
    name: "presetList",
    component: () => import("@/views/PresetList.vue"),
  },
  {
    meta: {
      title: i18n.global.t("create-preset"),
    },
    path: "/presets/create",
    name: "createPreset",
    component: () => import("@/views/CreatePreset.vue"),
  },
  {
    meta: {
      title: i18n.global.t("edit-preset"),
    },
    path: "/presets/:name/edit",
    name: "editPreset",
    component: () => import("@/views/EditPreset.vue"),
  },
  {
    meta: {
      title: i18n.global.t("preset-details"),
    },
    path: "/presets/:name",
    name: "presetDetails",
    component: () => import("@/views/PresetDetails.vue"),
  },
  {
    meta: {
      title: i18n.global.t("fee-list.title"),
    },
    path: "/fees",
    name: "feeList",
    component: () => import("@/views/FeeList.vue"),
  },
  {
    meta: {
      title: i18n.global.t("snipe-automation-config-list"),
    },
    path: "/snipeAutomation/configuration",
    name: "snipeAutomationConfiguration",
    component: () => import("@/snipeAutomation/configuration/List.vue"),
  },
  {
    meta: {
      title: i18n.global.t("create-snipe-automation-config"),
    },
    path: "/snipeAutomation/configuration/create",
    name: "createSnipeAutomationConfiguration",
    component: () => import("@/snipeAutomation/configuration/Create.vue"),
  },
  {
    meta: {
      title: i18n.global.t("edit-snipe-automation-config"),
    },
    path: "/snipeAutomation/configuration/:name/edit",
    name: "editSnipeAutomationConfiguration",
    component: () => import("@/snipeAutomation/configuration/Edit.vue"),
  },
  {
    meta: {
      title: i18n.global.t("snipe-automation-config-details"),
    },
    path: "/snipeAutomation/configuration/:name",
    name: "snipeAutomationConfigurationDetails",
    component: () => import("@/snipeAutomation/configuration/Details.vue"),
  },
  {
    meta: {
      title: i18n.global.t("snipe-automation-signals"),
    },
    path: "/snipeAutomation/signals",
    name: "snipeAutomationSignals",
    component: () => import("@/snipeAutomation/signals/View.vue"),
  },
  {
    meta: {
      title: i18n.global.t("copy-trade.title"),
    },
    path: "/copyTrade",
    name: "copyTrade",
    component: () => import("@/copyTrade/View.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.general.title"),
    },
    path: "/docs/general",
    name: "generalDocs",
    component: () => import("@/views/documentation/General.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.authentication.title"),
    },
    path: "/docs/authentication",
    name: "authenticationDocs",
    component: () => import("@/views/documentation/Authentication.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.accounts.title"),
    },
    path: "/docs/account",
    name: "accountDocs",
    component: () => import("@/views/documentation/Account.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.presets.title"),
    },
    path: "/docs/preset",
    name: "presetDocs",
    component: () => import("@/views/documentation/Preset.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.snipe.title"),
    },
    path: "/docs/snipe",
    name: "snipeDocs",
    component: () => import("@/views/documentation/Snipe.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.deposit.title"),
    },
    path: "/docs/deposit",
    name: "depositDocs",
    component: () => import("@/views/documentation/Deposit.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.withdraw.route"),
    },
    path: "/docs/withdrawal",
    name: "withdrawalDocs",
    component: () => import("@/views/documentation/Withdrawal.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.package.route"),
    },
    path: "/docs/package",
    name: "packageDocs",
    component: () => import("@/views/documentation/Package.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.fee.title"),
    },
    path: "/docs/fee",
    name: "feeDocs",
    component: () => import("@/views/documentation/Fee.vue"),
  },
  {
    meta: {
      title: i18n.global.t("user-guide.fee.title"),
    },
    path: "/docs/copyTrade",
    name: "copyTradeDocs",
    component: () => import("@/views/documentation/CopyTrade.vue"),
  },
  {
    meta: {
      title: i18n.global.t("administration.user.title"),
    },
    path: "/admin/user",
    name: "userAdministration",
    component: () => import("@/administration/user/View.vue"),
  },
  {
    meta: {
      title: i18n.global.t("notifications.title"),
    },
    path: "/admin/notification",
    name: "notificationAdministration",
    component: () => import("@/administration/notification/View.vue"),
  },
  {
    meta: {
      title: i18n.global.t("administration.statistics.title"),
    },
    path: "/admin/statistics",
    name: "statisticsAdministration",
    component: () => import("@/administration/statistics/View.vue"),
  },
  {
    meta: {
      title: i18n.global.t("not-found"),
    },
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: Error,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (store.state.auth.initialValidationOngoing || store.state.auth.loggedIn) {
    if (
      !store.state.initialLoadRunning &&
      !store.getters["packages/active"] &&
      ![
        "packages",
        "giftCodes",
        "vouchers",
        "userAdministration",
        "notificationAdministration",
        "statisticsAdministration",
      ].includes(to.name)
    ) {
      next({ name: "packages" });
    } else {
      next();
    }
  } else if (
    to.name === "login" ||
    to.name === "register" ||
    to.name === "requestPasswordReset" ||
    to.name === "resetPassword" ||
    to.name === "landing"
  ) {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router;
