import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import router from "@/router";
import { PairCheckerTypes } from "@/utils";
import axios from "axios";
import BN from "bn.js";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export interface ChainRelatedPackageDetails {
  minimumSnipeAmount: BN;
  maximumFeeOwed: BN;
  price: BN;
}

export interface Package {
  id: number;
  name: string;
  maximumParallelSnipes: number;
  maximumSnipeHistoryLength: number;
  maximumAllowedAccounts: number;
  maximumNumberOfPresets: number;
  accountImportAllowed: boolean;
  buyCommission: number;
  sellCommission: number;

  chainRelated: Record<string, ChainRelatedPackageDetails>;

  minimumSnipeAmount: BN;
  maximumFeeOwed: BN;

  featureFlags: Record<string, boolean>;
}

export type PackageHistoryEntry = {
  createdAt: Date;
  expiresAt: Date;
  renew: boolean;
};

export interface PackageState {
  items: Package[];
  active?: Package;
  historyEntry?: PackageHistoryEntry;
}

const packageState: PackageState = {
  items: [],
};

const packageGetters: GetterTree<PackageState, RootState> = {
  items(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    return state.items.map((p) => ({
      ...p,
      ...p.chainRelated[rootState.chain as string],
    }));
  },
  active(state): Package | undefined {
    return state.active;
  },
  isActivePackageCancelled(state): boolean {
    return !state.historyEntry?.renew;
  },
  expiryDate(state): Date | undefined {
    return state.historyEntry?.expiresAt;
  },
  availablePairCheckers(state): { id: string; label: string }[] {
    if (state.active?.featureFlags?.tokenSnifferChecker === false) {
      return PairCheckerTypes.filter((p) => p.id !== "tokenSniffer");
    }

    return PairCheckerTypes;
  },
};

const packageMutations: MutationTree<PackageState> = {
  setPackages(state, payload: Package[]) {
    state.items = payload;
  },
  handleUserPackageChange(
    state,
    payload: { package: Package; historyEntry: PackageHistoryEntry }
  ) {
    if (payload.package) {
      state.active = payload.package;
    } else {
      router.push({ name: "packages" });
    }

    if (payload.historyEntry) {
      state.historyEntry = {
        ...payload.historyEntry,
        createdAt: new Date(payload.historyEntry.createdAt),
        expiresAt: new Date(payload.historyEntry.expiresAt),
      };
    }
  },
};

const packageActions: ActionTree<PackageState, RootState> = {
  async read({ commit }) {
    try {
      const packagesResponse = await axios.get(apiBasePath + "/package");
      commit("setPackages", packagesResponse.data);
      const activePackageResponse = await axios.get(
        apiBasePath + "/package/active"
      );
      commit("handleUserPackageChange", activePackageResponse.data);
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        showApiErrorResponse(error);
      }
    }
  },
  activate(_, payload) {
    axios
      .post(`${apiBasePath}/package/${payload}/activate`)
      .then(() => {
        showSuccess("package-activated");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  cancel() {
    axios
      .post(apiBasePath + "/package/cancel")
      .then(() => {
        showSuccess("package-cancelled");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const packageModule: Module<PackageState, RootState> = {
  namespaced: true,
  state: packageState,
  getters: packageGetters,
  mutations: packageMutations,
  actions: packageActions,
};

export default packageModule;
